<template>
  <div class="member-detail">
    <div>
      <div class="mb-2" style="color:#f00;"
        v-if="data.close_account == 2">此账号已被注销</div>
      <a-row>
        <a-col :span="5">
          <span>昵称：</span>
          <span>{{ data.nickname }}</span>
        </a-col>
        <template v-if="data.close_account != 2">
          <a-col :span="5">
            <span>微信：</span>
            <a-tooltip v-if="data.unionid" :title="data.wx_tips" :overlayStyle="{ maxWidth:'500px', whiteSpace: 'pre-line' }">
              <span>已绑定</span>
            </a-tooltip>
            <span v-else>未绑定</span>
          </a-col>
          <a-col :span="7">
            <span>绑定手机：</span>
            <span>{{ data.phone || "未绑定" }}</span>
            <a-button class="ml-4 p-0" type="link" size="small" 
              v-if="hasAuthPhone" @click="showEditPhone=true">修改</a-button>
          </a-col>
          <a-col :span="7">
            <span>生日：</span>
            <span>{{ data.birthday || "未设置" }}</span>
            <a-button class="ml-4 p-0" type="link" size="small" 
              v-if="hasAuthBirthday" @click="showEditBirthday=true">修改生日</a-button>
          </a-col>
        </template>
      </a-row>

      <a-row class="mt-3" v-if="data.close_account != 2">
        <!-- <a-col :span="6">
          <span>会员等级：</span>
          <span>{{ data.level }}</span>
        </a-col> -->
        <!-- <a-col :span="9">
          <span>成长值：</span>
          <span>{{ data.growth_value}}</span>
        </a-col> -->
        <a-col :span="5">
          <span>积分：</span>
          <span>{{ data.integral }}</span>
          <a-button class="ml-4 p-0" type="link" size="small" 
            v-if="hasAuthIntegral" @click="showAddIntegral=true">增减</a-button>
        </a-col>
        <a-col :span="12">
          <span>注册时间：</span>
          <span>{{ data.reg_time }}</span>
        </a-col>
        <a-col :span="7">
          <div class="flex" style="justify-content:flex-end;">
            <a-button
                v-if="hasAuthWriteOff"
                class="ml-4 p-0" style="color:#f00;" type="link" size="small" 
                @click="showWriteOff=true">注销</a-button>
          </div>
        </a-col>
      </a-row>

      <template v-if="data.card_no">
        <h5 class="mt-3 title-bg flex items-center" style="margin-left:-10px;">
          <div>钱包（卡号：{{data.card_no}}）</div>
          <template v-if="data.vip_card_disable_pay==2">
            <span class="ml-8" style="color:#f00;">此钱包已禁止支付使用</span>
            <a-button class="ml-1 p-0" type="link" size="small" 
              v-if="hasAuthUnbanVip"
              @click="handlerUnbanVip">点击解除</a-button>
          </template>
          <a-button class="ml-1 p-0" type="link" size="small" 
              v-else-if="hasAuthBanVip"
              @click="handlerBanVip">禁用该钱包</a-button>
        </h5>
        <a-row class="mt-3">
          <a-col :span="6">
            <span>钱包：</span>
            <span>余额{{ data.vip_card_balance }}元</span>
            <a-button class="ml-4 p-0" type="link" size="small" 
              v-if="hasAuthRefund" @click="handlerApplyRefund">申请退余额</a-button>
          </a-col>
          <a-col :span="18">
            <span>开卡时间/城市：</span>
            <span>{{ data.open_card_time }}（{{ data.open_region }}）</span>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <h5 class="mt-4 title-bg">此用户未开通钱包</h5>
      </template>

      <!-- 黑名单 -->
      <div v-if="isInBlackList" class="mt-2" style="background:#eee; padding:10px 20px;">
        <div>
          <span style="color:#f00;">此用户是黑名单用户</span>
          <a-button class="ml-2 p-0" type="link" size="small" 
            v-if="hasAuthClearBlack"
            @click="cancelBlack">点击解除</a-button>
        </div>
        <div class="flex align-center mt-2">
          <span>拉黑备注: </span>
          <div class="ml-3" style="flex:1;">
            <template v-if="hasAuthEditBlack">
                <a-textarea v-model="blackListRemarks"
                    :auto-size="{ minRows: 1, maxRows: 8 }"></a-textarea>
              <a-button class="mt-2" type="primary" size="small" @click="saveBlackRemarks()">修改备注</a-button>
            </template>
            <p v-else>{{ blackListRemarks }}</p>
          </div>
        </div>
      </div>
      <div v-else class="mt-2">
        <a-button type="link" style="padding:0;"
            v-if="hasAuthAddBlack"
            @click="addBlack">拉黑此账号</a-button>
      </div>

      <a-divider />

      <div class="flex justify-between">
        <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
          <a-tab-pane key="key_vip" tab="钱包流水"></a-tab-pane>
          <a-tab-pane key="key_order" tab="用户的线上订单"></a-tab-pane>
          <!-- <a-tab-pane key="key_offline" tab="小程序线下核销"></a-tab-pane> -->
          <a-tab-pane key="key_coupon" tab="优惠券记录"></a-tab-pane>
          <a-tab-pane key="key_gift" tab="购买礼品卡"></a-tab-pane>
          <a-tab-pane key="key_integral" tab="积分记录"></a-tab-pane>
          <a-tab-pane key="key_storecard" tab="绑定的实体卡"></a-tab-pane>
          <!-- <a-tab-pane key="key_address" tab="收货地址"></a-tab-pane> -->
        </a-tabs>
      </div>

      <div class="mt-3">
        <!-- 钱包 -->
        <template v-if="activeKey == 'key_vip'">
          <div class="mb-3">
            <span>钱包金额变更时间：</span>
            <a-range-picker style="width:230px" valueFormat="YYYY-MM-DD"
              v-model="vipCardSearchForm.time" />
          </div>
          <base-table :columnsData="vipCardColumnsList"
                      :tableData="vipCardTableData"
                      :getListFunc="getVipCardLogList"
                      :total="vipCardSearchForm.total"
                      :page.sync="vipCardSearchForm.page"
                      :pageSize.sync="vipCardSearchForm.page_count">
            <template #operation="{record}">
              <a-button type="link" v-if="record.order_no" @click="handlerOrderDetail(record.order_no)">订单详情</a-button>
              <a-button type="link" v-if="record.isGiftCard" 
                  @click="handlerVipDetail(record)">查看礼品卡</a-button>
              <a-button type="link" v-if="record.isRecharge" 
                  @click="handlerVipDetail(record)">充值流水</a-button>
              <a-button type="link" v-if="record.isBalanceRefund" 
                  @click="handlerVipDetail(record)">退款详情</a-button>
            </template>
          </base-table>
        </template>
        
        <!-- 订单列表 -->
        <template v-if="activeKey == 'key_order'">
          <div class="mb-3">
            <span>下单日期：</span>
            <a-range-picker style="width:230px" v-model="orderSearchForm.order_add_time_arr" valueFormat="YYYY-MM-DD" />
          </div>
          <base-table :columnsData="orderColumnsList"
                      rowKey="order_no"
                      :tableData="orderTableData"
                      :getListFunc="getOrderList"
                      :total="orderSearchForm.total"
                      :page.sync="orderSearchForm.page"
                      :pageSize.sync="orderSearchForm.page_count">
            <template #order_no="{text}">
              <a-button class="p-0" type="link" @click="handlerOrderDetail(text)">{{text}}</a-button>
            </template>
            <template #order_status="{ text }">
              <!-- -2-已取消（旺店通需自行处理），-1-已取消，1-未支付，2-未审核，3-已分派，4-已完成） -->
              <a-tag class="mr-0"
                :color="text < 0 ? 'red' : (text < 3 ? '' : (text < 4 ? 'blue' : 'green')) "
              >{{ formatDetailOrderStatus(text) }}</a-tag>
            </template>
          </base-table>
        </template>

        <!-- 优惠券列表 -->
        <template v-if="activeKey == 'key_coupon'">
          <div class="mb-3 flex">
            <div>
              <span>领取日期：</span>
              <a-range-picker style="width:230px" v-model="couponSearchForm.received_time" valueFormat="YYYY-MM-DD" />
            </div>
            <div class="ml-4">
              <span>使用日期：</span>
              <a-range-picker style="width:230px" v-model="couponSearchForm.use_time" valueFormat="YYYY-MM-DD" />
            </div>
          </div>
          <base-table :columnsData="couponColumnsList"
                      rowKey="id"
                      :tableData="couponTableData"
                      :getListFunc="getCouponList"
                      :total="couponSearchForm.total"
                      :page.sync="couponSearchForm.page"
                      :pageSize.sync="couponSearchForm.page_count">
          </base-table>
        </template>

        <!-- 购买礼品卡 -->
        <template v-if="activeKey == 'key_gift'">
          <div class="mb-3 flex">
            <div>
              <span>购买日期：</span>
              <a-range-picker style="width:230px" v-model="giftSearchForm.pay_time" valueFormat="YYYY-MM-DD" />
            </div>
          </div>
          <base-table :columnsData="giftColumnsList"
                      rowKey="id"
                      :tableData="giftTableData"
                      :getListFunc="getGiftCardOrderList"
                      :total="giftSearchForm.total"
                      :page.sync="giftSearchForm.page"
                      :pageSize.sync="giftSearchForm.page_count">
              <template #operation="{record}">
                <a-button type="link" class="p-0" 
                    @click="handlerGiftDetail(record)">详情</a-button>
              </template>
          </base-table>
        </template>

        <!-- 积分记录 -->
        <template v-if="activeKey == 'key_integral'">
          <div class="mb-3 flex">
            <div>
              <span>日期：</span>
              <a-range-picker style="width:230px" v-model="integralSearchForm.time" valueFormat="YYYY-MM-DD" />
            </div>
          </div>
          <base-table :columnsData="integralColumnsList"
                      rowKey="id"
                      :tableData="integralTableData"
                      :getListFunc="getIntegralList"
                      :total="integralSearchForm.total"
                      :page.sync="integralSearchForm.page"
                      :pageSize.sync="integralSearchForm.page_count">
          </base-table>
        </template>

        <!-- 实体卡 -->
        <template v-if="activeKey == 'key_storecard'">
          <base-table rowKey="card_no"
                      :columnsData="storecardColumnsList"
                      :tableData="storecardTableData">
              <!-- <template #operation="{record}">
                <a-button type="link" class="p-0" 
                    @click="handlerUnbind(record)">解绑</a-button>
              </template> -->
              <template #cover_url="{text}">
                <a v-if="text" :href="text" target="_blank">
                  <img :src="text+cutQuery(80,50)" style="height:50px;" />
                </a>
                <span v-else>无封面</span>
              </template>
          </base-table>
        </template>

        <!-- 实体卡 -->
        <template v-if="activeKey == 'key_address'">
          <base-table rowKey="card_no"
                      :columnsData="addressColumnsList"
                      :tableData="addressTableData">
              <!-- <template #operation="{record}">
                <a-button type="link" class="p-0" 
                    @click="handlerUnbind(record)">解绑</a-button>
              </template> -->
          </base-table>
        </template>
        
      </div>
    </div>

    <!-- 注销 -->
    <write-off v-if="showWriteOff" :show.sync="showWriteOff"
         :memberId="memberId"
         @ok="wirteOffComplete"></write-off>

    <!-- 加黑名单 -->
    <addblack-modal v-if="showAddBlack" :show.sync="showAddBlack"
         :memberId="memberId"
         @added="addBlackComplete"></addblack-modal>

    <!-- 礼品卡充值 -->
    <GiftRechargeDetail v-if="showGiftRechargeDetail" :show.sync="showGiftRechargeDetail"
         :info="vipDetailRecord"></GiftRechargeDetail>
    <!-- 钱包充值 -->
    <rechargeDetail v-if="showRechargeDetail" :show.sync="showRechargeDetail"
        :rechargeId="vipcardRechargeId"></rechargeDetail>
    <!-- 钱包退余额 -->
    <RefundDetail v-if="showBalanceRefundDetail" :show.sync="showBalanceRefundDetail" 
        :id="vipDetailRecord.work_sheet_id" onlyRead />
    <!-- 购买礼品卡 -->
    <GiftDetail v-if="showGiftDetail" :show.sync="showGiftDetail"
        :info="giftDetail"></GiftDetail>
    <!-- 加积分 -->
    <AddIntegral v-if="showAddIntegral" :show.sync="showAddIntegral"
        :memberId="memberId"
        @added="addIntegralComplete"></AddIntegral>
    <!-- 修改生日 -->
    <edit-birthday v-if="showEditBirthday" :show.sync="showEditBirthday"
        :memberId="memberId"
        :date="data.birthday"
        @ok="initData"></edit-birthday>

    <!-- 修改生日 -->
    <edit-phone v-if="showEditPhone" :show.sync="showEditPhone"
        :memberId="memberId"
        @ok="initData"></edit-phone>

    <!-- 申请并审核退款 -->
    <RefundAudit v-if="isShowRefund" :show.sync="isShowRefund" :id="applyRefundId" />
  </div>
</template>

<script>
import { formatDetailOrderStatus } from "@/utils/type";
import { checkAuth, checkPage } from "@/utils/authData.js";
import {
  getMemberDetail,
  getUserBlackInfo,
  updateUserBlackInfo,
  pushOutUserBlack,
  getVipChangeLog,
  getIntegralRecord,
  getStorecardList,
  getAddressList,
  banUserVipcard,
  unbanUserVipcard
} from "@/api/vip.js";
import { getAllOrderList } from "@/api/customer.js";
import { getRecord } from "@/api/activity/coupon.js";
import { getGiftCardOrderList } from "@/api/wallet.js";
import { applyWalletRefund } from "@/api/refund.js"

import pageData from "./columns"
import writeOff from "./components/write-off.vue"
import addblackModal from "@/components/order/addblack-modal.vue"
import GiftRechargeDetail from "./components/gift-recharge-detail.vue"
import GiftDetail from "./components/gift-detail.vue"
import rechargeDetail from "./components/rechargeDetail.vue"
import RefundDetail from "../customer-service/wallet-refund/components/refund-detail"
import AddIntegral from "./components/add-integral.vue"
import EditBirthday from "./components/edit-birthday.vue";
import EditPhone from "./components/edit-phone.vue";
import RefundAudit from "../customer-service/wallet-refund/components/refund-audit"
import { cutQuery } from '@/utils/ossImg'

export default {
  components: {
    writeOff,
    addblackModal,
    GiftRechargeDetail,
    rechargeDetail,
    RefundDetail,
    GiftDetail,
    AddIntegral,
    EditBirthday,
    EditPhone,
    RefundAudit
  },
  props: {
    memberId: {
      type: [String, Number],
      default: "",
    },
    num: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ...pageData,
      cutQuery,

      cardNo: "",
      formatDetailOrderStatus,
      requestID: "",
      data: {},

      hasAuthWriteOff:    checkAuth("member:close_account"),
      hasAuthAddBlack:    checkAuth("member_black_list:add"),
      hasAuthClearBlack:  checkAuth("member_black_list:cancel"),
      hasAuthEditBlack:   checkAuth("member_black_list:update"),
      hasAuthIntegral:    checkAuth("member:add_integral"),
      hasAuthBirthday:    checkAuth("member:update"),
      hasAuthPhone:       checkAuth("member:update"),
      hasAuthBanVip:      checkAuth('member:vip_card_disable_pay'),
      hasAuthUnbanVip:    checkAuth('member:vip_card_disable_pay_cancel'),
      hasAuthRefund:      checkPage('/kf-vip-card-refund'),

      showAddIntegral: false,

      activeKey: "",
      // 钱包
      vipCardSearchForm: {
        member_id: this.memberId,
        time: [],
        change_time_start: "",
        change_time_end: "",
        total: 0,
        page: 1,
        page_count: 20,
      },
      vipCardTableData: [],

      // 订单
      orderSearchForm: {
        member_id: this.memberId,
        order_no: "",
        order_source: 0,
        order_status: 0,
        order_add_time_arr: [],
        order_add_time_start: "",
        order_add_time_end: "",
        consignee_recipient_name: "",
        consignee_recipient_phone: "",
        consignee_recipient_time_arr: [],
        consignee_recipient_time_start: "",
        consignee_recipient_time_end: "",
        consignee_address: "",
        order_type: 0,
        goods_type: 0,
        pay_flow_no: "",
        pay_mode: 0,

        total: 0,
        page: 1,
        page_count: 20,
      },
      orderTableData: [],

      // 线下核销
      offlineSearchForm: {
        member_id: this.memberId,
        time: [],
        change_time_start: "",
        change_time_end: "",
        total: 0,
        page: 1,
        page_count: 20,
      },
      offlineTableData: [],

      // 优惠券
      couponSearchForm: {
        member_id: this.memberId,
        received_time: [],
        use_time: [],
        received_time_start: "",
        received_time_end: "",
        use_time_start: "",
        use_time_end: "",
        total: 0,
        page: 1,
        page_count: 20,
      },
      couponTableData: [],

      // 礼品卡
      giftSearchForm: {
        member_id: this.memberId,
        order_status: 1,
        pay_time: [],
        pay_time_start: "",
        pay_time_end: "",
        total: 0,
        page: 1,
        page_count: 20,
      },
      giftTableData: [],

      // 积分
      integralSearchForm: {
        member_id: this.memberId,
        time: [],
        add_time_start: "",
        add_time_end: "",
        total: 0,
        page: 1,
        page_count: 20,
      },
      integralTableData: [],
      storecardTableData: [],
      addressTableData: [],

      showEditBirthday: false,
      showEditPhone: false,

      showAddBlack: false,
      isInBlackList: false,
      blackListRemarks: "",

      showWriteOff: false,

      vipDetailRecord: null,
      showGiftRechargeDetail: false,
      vipcardRechargeId: null,
      showRechargeDetail: false,
      showBalanceRefundDetail: false,

      giftDetail: null,
      showGiftDetail: false,

      // 申请退余额
      applyRefundId: null,
      isShowRefund: false,
    };
  },
  watch: {
    num() {
      this.initData();
    },
    "vipCardSearchForm.time"() {
      this.vipCardSearchForm.page = 1;
      this.getVipCardLogList();
    },
    "orderSearchForm.order_add_time_arr"() {
      this.orderSearchForm.page = 1;
      this.getOrderList();
    },
    "couponSearchForm.received_time"() {
      this.orderSearchForm.page = 1;
      this.getCouponList();
    },
    "couponSearchForm.use_time"() {
      this.orderSearchForm.page = 1;
      this.getCouponList();
    },
    "giftSearchForm.pay_time"() {
      console.log("2222");
      this.giftSearchForm.page = 1;
      this.getGiftCardOrderList();
    },
    "integralSearchForm.time"() {
      this.integralSearchForm.page = 1;
      this.getIntegralList();
    },
  },
  async created () {
    if(this.$route.query.member_id){ // 如果是新开页：参数member_id
      this.memberId = this.$route.query.member_id
    }else if(this.$route.query.card_no){ // 如果是新开页：参数钱包编号
      this.cardNo = this.$route.query.card_no
    }

    // 加载数据
    await this.initData();
    // this.handlerTypeChange("key_vip");
  },
  methods: {
    async initData() {
      const { data, code } = await getMemberDetail({
        member_id: this.memberId,
        card_no: this.cardNo,
      });
      if (code === 0) {
        if(data.unionid){
          data.wx_tips = "openid："+data.openid+"\n unionid："+data.unionid
        }
        this.data = data;
        this.$emit("getDetail", this.data);
        if (!this.memberId) {
          this.memberId = data.member_id;
        }
        this.initBlackList();
        // this.handlerTypeChange("key_vip")
      }
    },

    handlerTypeChange(key) {
      this.activeKey = key;

      if (key == "key_vip") {
        this.getVipCardLogList();
      } else if (key == "key_order") {
        this.getOrderList();
      } else if (key == "key_offline") {
        // this.getOfflineList()
      } else if (key == "key_coupon") {
        this.getCouponList();
      } else if (key == "key_gift") {
        this.getGiftCardOrderList();
      } else if (key == "key_integral") {
        this.getIntegralList();
      } else if (key == "key_storecard") {
        this.getStorecardList();
      } else if (key == "key_address") {
        this.getAddressList();
      }
    },

    async initBlackList() {
      const { data, code } = await getUserBlackInfo({
        member_id: this.memberId,
      });
      if (code == 0) {
        this.isInBlackList = data.blacklist_status == 2;
        this.blackListRemarks = data.blacklist_remarks || "";
      }
    },
    // 加黑名单
    async addBlack() {
      this.showAddBlack = true;
    },
    addBlackComplete(e) {
      this.showAddBlack = false;
      this.isInBlackList = true;
      this.blackListRemarks = e.remarks;
    },
    // 解除黑名单
    async cancelBlack() {
      pushOutUserBlack({
        member_id: this.memberId,
      }).then((res) => {
        if (res.code === 0) {
          this.isInBlackList = false;
          this.blackListRemarks = "";
          this.$message.success("已解除");
        }
      });
    },
    // 修改黑名单
    async saveBlackRemarks() {
      updateUserBlackInfo({
        member_id: this.memberId,
        blacklist_remarks: this.blackListRemarks,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("已保存");
        }
      });
    },
    // 禁用钱包
    handlerBanVip(){
      const _this = this
      this.$confirm({
        title: "提示",
        content: "确定禁用钱包？（禁用后 用户钱包将不能支付使用）",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        async onOk() {
          banUserVipcard({
            member_id: _this.memberId
          }).then((res) => {
            if (res.code === 0) {
              _this.$message.success("已禁用");
              _this.initData()
            }
          })
        },
        onCancel() {
          console.log("Cancel")
        },
      })
    },
    // 解禁钱包
    handlerUnbanVip(){
      const _this = this
      this.$confirm({
        title: "提示",
        content: "确定解禁？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        async onOk() {
          unbanUserVipcard({
            member_id: _this.memberId
          }).then((res) => {
            if (res.code === 0) {
              _this.$message.success("已解禁");
              _this.initData()
            }
          })
        },
        onCancel() {
          console.log("Cancel")
        },
      })
    },

    // 注销
    wirteOffComplete() {
      this.showWriteOff = false;
      this.initData()
    },

    async getVipCardLogList() {
      const params = this.vipCardSearchForm;
      params.member_id = this.memberId;
      if (this.vipCardSearchForm.time.length == 2) {
        params.change_time_start = this.vipCardSearchForm.time[0] + " 00:00:00";
        params.change_time_end = this.vipCardSearchForm.time[1] + " 23:59:59";
      } else {
        params.change_time_start = "";
        params.change_time_end = "";
      }

      const { data, code } = await getVipChangeLog(params);
      if (code === 0) {
        this.vipCardTableData = data.list.map((el, i) => {
          el.isGiftCard      = !!(el.vip_recharge_card_info && el.vip_recharge_card_info['order_no'])
          el.isRecharge      = !!(el.vip_recharge_info && el.vip_recharge_info['recharge_id'])
          el.isBalanceRefund = !!(el.vip_refund_info && el.vip_refund_info['work_sheet_id'])

          el.key = i + el.change_time;
          return el;
        });
        this.vipCardSearchForm.total = data.total_count;
      }
    },
    async getOrderList() {
      const params = this.orderSearchForm;
      params.member_id = this.memberId;
      params.order_add_time_start = "";
      params.order_add_time_end = "";
      params.consignee_recipient_time_start = "";
      params.consignee_recipient_time_end = "";
      if (params.order_add_time_arr.length > 0) {
        params.order_add_time_start = params.order_add_time_arr[0];
        params.order_add_time_end = params.order_add_time_arr[1];
      }
      if (params.consignee_recipient_time_arr.length > 0) {
        params.consignee_recipient_time_start = params.consignee_recipient_time_arr[0]
        params.consignee_recipient_time_end = params.consignee_recipient_time_arr[1]
      }
      const { data, code } = await getAllOrderList(params);
      if (code === 0) {
        data.list.map((el)=>{
          if(el.consignee_recipient_time_start){
            el.time = this.formatTime(el.consignee_recipient_time_start) + "~" +
                      this.formatTime(el.consignee_recipient_time_end.split(' ')[1])
          }else{
            el.timer = ""
          }
          return el;
        });
        this.orderTableData = data.list;
        this.orderSearchForm.total = data.total_count;
      }
    },

    async getCouponList() {
      const params = this.couponSearchForm;
      params.member_id = this.memberId;
      params.received_time_start = "";
      params.received_time_end = "";
      params.use_time_start = "";
      params.use_time_end = "";
      if (params.received_time.length == 2) {
        params.received_time_start = params.received_time[0];
        params.received_time_end = params.received_time[1];
      }
      if (params.use_time.length == 2) {
        params.use_time_start = params.use_time[0];
        params.use_time_end = params.use_time[1];
      }
      const { data, code } = await getRecord(params);
      if (code === 0) {
        this.couponTableData = data.list;
        this.couponSearchForm.total = data.total_count;
      }
    },

    async getGiftCardOrderList() {
      const params = this.giftSearchForm;
      params.member_id = this.memberId;
      params.pay_time_start = "";
      params.pay_time_end = "";
      if (params.pay_time.length == 2) {
        params.pay_time_start = params.pay_time[0];
        params.pay_time_end = params.pay_time[1];
      }
      const { data, code } = await getGiftCardOrderList(params);
      if (code === 0) {
        this.giftTableData = data.list;
        this.giftSearchForm.total = data.total_count;
      }
    },
    async getIntegralList() {
      const params = this.integralSearchForm;
      params.member_id = this.memberId;
      params.add_time_start = "";
      params.add_time_end = "";
      if (params.time.length == 2) {
        params.add_time_start = params.time[0];
        params.add_time_end = params.time[1];
      }
      const { data, code } = await getIntegralRecord(params);
      if (code === 0) {
        this.integralTableData = data.list.map((el) => {
          if (el.integral > 0) {
            el.integral = "+" + el.integral;
          }
          return el;
        });
        this.integralSearchForm.total = data.total_count;
      }
    },
    addIntegralComplete() {
      this.initData();
      this.handlerTypeChange("key_integral");
    },

    handlerApplyRefund(){
      if(Number(this.data.vip_card_balance) == 0){
        this.$message.info("无余额可退")
        return
      }
      
      const _this = this
      this.$confirm({
        title: "提示",
        content: "确认要替用户申请退余额?\n（提交后：顾客钱包会清零，后续需要让财务打款并上传凭证）",
        okText: "确定（用户钱包会立即清零）",
        okType: "danger",
        cancelText: "取消",
        async onOk() {
          const { code, data } = await applyWalletRefund({
            member_id: _this.memberId
          })
          if (code == 0) {
            _this.$message.info("已申请、请审核")
            _this.applyRefundId = data.id
            _this.isShowRefund = true
          }
        },
        onCancel() {
          console.log("Cancel")
        },
      })
    },

    async getStorecardList() {
      const { data, code } = await getStorecardList({
        member_id: this.memberId
      });
      if (code == 0) {
        this.storecardTableData = data.list
      }
    },
    async getAddressList() {
      const { data, code } = await getAddressList({
        member_id: this.memberId
      });
      if (code == 0) {
        this.addressTableData = data.list
      }
    },

    handlerOrderDetail(order_no) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: order_no },
      });
      window.open(newPage.href, "_blank");
    },

    handlerVipDetail(record) {
      if (record.isGiftCard) {
        this.vipDetailRecord = record.vip_recharge_card_info;
        this.showGiftRechargeDetail = true;
      }
      if (record.isRecharge) {
        this.vipcardRechargeId = record.vip_recharge_info.recharge_id
        this.showRechargeDetail = true;
      }
      if (record.isBalanceRefund) {
        this.vipDetailRecord = record.vip_refund_info;
        this.showBalanceRefundDetail = true;
      }
    },

    handlerGiftDetail(record) {
      this.giftDetail = record;
      this.showGiftDetail = true;
    },

    formatTime(time) {
      if (/\d{2}:\d{2}:\d{2}/.test(time)) {
        return time.slice(0, -3);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.order-detail-container {
  // margin: 20px;
  padding: 20px 20px 50px 20px;
  background: #ffffff;
}
.shop-table-class {
  // margin-left: -10px;
}
.order-detail-title {
  width: 70px;
  text-align: right;
  display: inline-block;
}
</style>